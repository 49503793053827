import mountain from "./mountain.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>CFiveDev</h1>
      <h2 className="App-italic">Where inspiration is a chain reaction!</h2>
      <p>
        more to come, but enjoy this mountain range (generated with{" "}
        <a href="https://scenario.gg" className="App-link" target="_blank">
          Scenario.gg
        </a>
        ) until then...
      </p>
      <img src={mountain} className="App-mountain" alt="mountain" />
    </div>
  );
}

export default App;
